<template src="./templates/results.html"></template>
<script>

import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Grid from '@c/Grid';
import Button from '@f/Button';
import {delQuestionnaireResult, getQuestionnaireResults} from '@api/questionnaire';

import Filter from './components/filter';

export default {
    name: 'QuestionnaireResults',
    components: {
        Button,
        PageTitle,
        PageHeader,
        Breadcrumbs,
        Grid,
        Filter,
    },
    props: {
        action: {
            type: String,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            results: [],
            filter: {},
        };
    },
    created() {
        this.initQResults();
    },
    methods: {
        async initQResults() {
            // TODO: implement pagination
            this.results = await getQuestionnaireResults(0, 500, this.filter);
        },
        async removeResult(id) {
            if (window.confirm('Вы уверены, что хотите удалить результаты опроса?')) {
                await delQuestionnaireResult(id);
                await this.initQResults();
            }
        },
        redirectToResult(id) {
            const resultCode = this.results.find((item) => item.id === id)?.result_code;
            this.$router.push({path: '/questionnaire/edit', query: {result_code: resultCode}});
        },
        onFilterChange(values) {
            this.filter = {...values};
            this.initQResults();
        }
    },
};
</script>
