<template src="../templates/filter.html"></template>
<script>

import {mapGetters} from 'vuex';
import Multiselect from '@vueform/multiselect';
import DateRangePicker from '@c/DateRangePicker';
import Input from '@f/Input';
import helpers from '../../../tools/helpers';

import '@vueform/multiselect/themes/default.css';
import '../../../layouts/default/styles/results.scss';

export default {
    name: 'Filter',
    components: {
        Multiselect,
        Input,
        DateRangePicker
    },
    props: {
        onChange: {
            type: Function,
            default() {
                return Function.prototype;
            }
        }
    },
    data() {
        return {
            values: {
                branch: [],
                createDateRange: {
                    start: null,
                    end: null,
                },
                user: '',
                partnerCode: '',
                contact: '',
                productCategory: '',
            },
        };
    },
    computed: {
        // мержим в computed геттеры из стора
        ...mapGetters({
            branches: 'branches',
        }),
    },
    watch: {
        'values.branch': {
            handler() {
                this.defaultWatchHandler();
            }
        },
        'values.createDateRange': {
            handler() {
                this.defaultWatchHandler();
            }
        },
        'values.user': {
            // eslint-disable-next-line func-names
            handler: helpers.debounceBuffer(function (value, old) {
                this.stringWatchHandler(value, old);
            }, 500),
        },
        'values.partnerCode': {
            handler: helpers.debounceBuffer(function (value, old) {
                this.stringWatchHandler(value, old);
            }, 500),
        },
        'values.contact': {
            handler: helpers.debounceBuffer(function (value, old) {
                this.stringWatchHandler(value, old);
            }, 500),
        },
        'values.productCategory': {
            handler: helpers.debounceBuffer(function (value, old) {
                this.stringWatchHandler(value, old);
            }, 500),
        },
    },
    created() {

    },
    methods: {
        onFilterChange() {
            this.onChange({...this.values});
        },
        defaultWatchHandler() {
            this.onFilterChange();
        },
        stringWatchHandler(val) {
            if (val.length > 2 || val.length === 0) {
                this.onFilterChange();
            }
        },
    },
};
</script>
