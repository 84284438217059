<template src="./templates/date-range-picker.html"></template>
<script>

import {DatePicker} from 'v-calendar';

const default_values = {
    id: null,
    type: 'text',
    class: ''
};

export default {
    name: 'FormDateRangePicker',
    components: {
        DatePicker
    },
    props: {
        preloader: {
            type: Boolean,
            default() {
                return false;
            }
        },
        modelValue: {
            type: Object,
            default() {
                return {
                    start: null,
                    end: null
                };
            },
        },
        attributes: {
            type: Object,
            default() {
                return default_values;
            }
        },
        label: {
            type: String,
            default() {
                return null;
            }
        },
        groupClass: {
            type: String,
            default() {
                return '';
            }
        },
        labelClass: {
            type: String,
            default() {
                return '';
            }
        }
    },
    emits: ['update:modelValue'],
    computed: {
        labelClassPrep() {
            const cl = `${(this.labelClass) ? `${this.labelClass} ` : ''}`;
            return `${cl}h6`;
        },
        attributesPrep() {
            const attrs = {...default_values, ...this.attributes};

            if (!attrs.id) {
                attrs.id = `form-input-${Math.floor(Math.random() * 100000)}`;
            }

            return attrs;
        }
    },
    watch: {
        value: {
            handler(v, oldV) {
                this.$emit('update:modelValue', v);
            },
            deep: true,
        }
    },
    data() {
        return {
            value: this.modelValue,
        };
    }
};
</script>
