<div class="preloader" v-if="preloader"></div>
<div :class="'form-group' + groupClass" v-else>
    <label v-if="label" :class="labelClassPrep" :for="attributes.id">{{label}}</label>
    <DatePicker
        is-range
        :mode="'date'"
        v-model="value"
        :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD',
        }"
    >
        <template v-slot="{ inputValue, inputEvents, isDragging }">
            <span class="date-range-label">
                <span
                    class="date-range-label-start"
                    v-on="inputEvents.start"
                >{{ value.start || 'Дата создания' }}</span>
                <span v-if="value.start">{{' - '}}</span>
                <span
                    class="date-range-label-end"
                    v-on="inputEvents.end"
                >{{ value.end || '' }}</span>
                <span
                    class="date-range-label-reset"
                    style="padding: 0 10px; font-weight: bold; color: #ff0000; cursor: pointer;"
                    v-if="value.start"
                    @click="value = {}"
                >{{'x'}}</span>
            </span>
        </template>
    </DatePicker>
</div>
