<div class="container">
<div class="row filter-block mb-3">
    <div class="col-12">
        <div class="row mb-2">
            <div class="col-3">
                <Multiselect
                    v-model="values.branch"
                    :options="branches"
                    :label="'full_name'"
                    placeholder="Филиал"
                    valueProp="id"
                    trackBy="full_name"
                    mode="tags"
                    :searchable="true"
                />
            </div>
            <div class="col-3">
                <DateRangePicker
                    v-model="values.createDateRange"
                />
            </div>
            <div class="col-3">
                <Input
                    :attributes="{placeholder: 'Менеджер'}"
                    v-model="values.user"
                />
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-3">
                <Input
                    :attributes="{placeholder: 'Код контрагента'}"
                    v-model="values.partnerCode"
                />
            </div>
            <div class="col-3">
                <Input
                    :attributes="{placeholder: 'Контактное лицо'}"
                    v-model="values.contact"
                />
            </div>
            <div class="col-3">
                <Input
                    :attributes="{placeholder: 'Категория товара'}"
                    v-model="values.productCategory"
                />
            </div>
        </div>
    </div>
</div>
</div>
