<div>
	<PageHeader>
		<div class="row c">
			<Breadcrumbs :items="[{title:'Опросник'},{title:'Результаты опросов',href:'/questionnaire/results'}]" />
		</div>
	</PageHeader>

	<PageTitle title="Результаты опросов" :params="{/*subtitle:'Здесь дополнительная информация'*/}" />

    <Filter
        :on-change="onFilterChange"
    />

    <Grid
            :columns="[
                {
                    name: 'id',
                    label: '№ п/п',
                    width: 'minmax(20px, 0.50fr)',
                },
                {
                    name: 'manager_name',
                    label: 'Менеджер',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'partner_code',
                    label: 'Контрагент',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'partner_name',
                    label: 'Наименование',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'partner_contact',
                    label: 'Контактное лицо',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'product_category_name',
                    label: 'Категория товара',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'order_id',
                    label: 'Заказ',
                    width: 'minmax(20px, 0.50fr)',
                },
                {
                    name: 'incoming_id',
                    label: 'ВО',
                    width: 'minmax(20px, 0.33fr)',
                },
                {
                    name: 'create_date',
                    label: 'Создано',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'edit_date',
                    label: 'Изменено',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'progress',
                    label: 'Ответы',
                    width: 'minmax(50px, 0.33fr)',
                },
                {
                    name: 'questions_count',
                    label: 'Кол-во вопросов',
                    width: 'minmax(50px, 0.33fr)',
                },
                {
                    slot_name: 'slot_actions',
                    label: 'Действия',
                    width: 'minmax(20px, 0.73fr)',
                },
            ]"
            :data="results"
            :onEdit="undefined/*redirectToResult*/"
            :onRemove="undefined/*removeResult*/"
    >
        <template v-slot:slot_actions="row">
            <Button
                type="a"
                :attributes="{
                    'target': '_blank'
                }"
                label="✎"
                :href="`/questionnaire2/edit?result_code=${row.data.result_code}`"
            />
        </template>
    </Grid>
</div>
